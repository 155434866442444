<template>
    <div>
        <div v-if="showImage" class="img-section">
            <b-overlay :show="showLoading" class="h-100" rounded="sm">
                <div :key="showPlaceholder">
                    <b-img :hidden="showPlaceholder" :src="imgData" :title="filenameData" fluid></b-img>
                    <i :class="placeholder" :hidden="!showPlaceholder" class="fas fa-image placeholder" fluid></i>
                </div>
            </b-overlay>
        </div>

        <div v-if="!showImage" class="filename-section">
            <b-spinner v-if="showLoading" label="Spinning" small></b-spinner>
            <span>{{ filenameData }}</span>
        </div>
        <div v-if="showButtons" class="button-bar">

            <div v-if="!disabled" v-b-tooltip.hover :title="$t('basics.upload')">
                <button
                    :disabled="showLoading"
                    class="nato-default-btn"
                    type="button"
                    @click="$refs.fileUploader.click()"
                >
                    <i class="fas fa-upload"></i>
                </button>
            </div>

            <div v-if="!disabled && showDelete" v-b-tooltip.hover :title="$t('basics.delete')">
                <button
                    :disabled="showLoading || formdata[field]==null"
                    class="nato-default-btn"
                    type="button"
                    @click="onDelete"

                >
                    <i class="fas fa-trash"></i>
                </button>
            </div>

            <div v-if="showDownload" v-b-tooltip.hover :title="$t('basics.download')">
                <button
                    :disabled="showLoading || formdata[field]==null"
                    class="nato-default-btn"
                    type="button"
                    @click="onDownload"
                >
                    <i class="fas fa-download"></i>
                </button>
            </div>

            <input
                :id="'uploader_' + field"
                ref="fileUploader"
                :accept="acceptTypes"
                :disabled="disabled"
                :required="required && formdata[field]==null"
                class="invisible-fileinput"
                type="file"
                @change="uploadFile">
        </div>

    </div>
</template>

<script>
export default {
    name: "FileHandler",
    props: {
        formdata: null,
        field: null,
        idField: null,
        disabled: false,
        upload_endpoint: null,
        download_endpoint: null,
        delete_endpoint: null,
        image_endpoint: null,
        showImage: true,
        showButtons: true,
        acceptTypes: null,
        eventId: null,
        placeholder: null,
        showDelete: true,
        showDownload: true,
        required: false,
        initialFilename: null
    },
    data() {
        return {
            imgData: null,
            filenameData: null,
            showLoading: false,
            showPlaceholder: false
        }
    },
    created() {
        this.showPlaceholderImage();
        if (this.getFormdataField) {
            this.loadImage();
        }
    },
    methods: {
        loadImage() {
            if (this.image_endpoint == null) {
                return
            }
            this.imgData = null;
            if (this.formdata[this.field] != null && this.formdata[this.field] != '') {
                this.showPlaceholder = false;
                this.showLoading = true;
                axios.get(this.image_endpoint + "/" + this.formdata[this.field])
                    .then(response => {
                        this.showLoading = false;
                        if (response.data.data.content_base64) {
                            this.filenameData = response.data.data.name;
                            if (this.showImage) {
                                var mimeType = response.data.data.mimeType;
                                if (mimeType == null || mimeType == '') {
                                    mimeType = 'image/jpg';
                                }
                                this.imgData = 'data:' + mimeType + ';base64, ' + response.data.data.content_base64
                            }
                        } else {
                            this.showPlaceholderImage();
                        }
                    });
            } else {
                //SHOW PLACEHOLDER image
                this.showPlaceholderImage();
            }
        },
        showPlaceholderImage() {
            this.showPlaceholder = true;
            if (this.initialFilename == null) {
                this.filenameData = this.$t('filehandler.no-file-selected');
            } else {
                this.filenameData = this.initialFilename;
            }
        },
        async uploadFile() {
            // Get the file from the input field
            const file = this.$refs.fileUploader.files[0]
            if (!this.validateSelectedFile(file)) {
                return;
            }
            var data = new FormData();
            data.append('file', file);
            data.append('eventId', this.eventId);
            data.append(this.idField, this.formdata[this.idField]);
            data.append('linkField', this.field);

            this.showLoading = true;
            axios.post(this.upload_endpoint, data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(response => {
                this.showLoading = false;
                this.formdata[this.field] = response.data.data.DocumentID;
                if (this.image_endpoint != null) {
                    this.loadImage();
                } else {
                    this.filenameData = file.name;
                }
                this.$refs.fileUploader.value = null; //so you can upload a new file with the same name
            }).catch(error => {
                this.showLoading = false;
                if (this.image_endpoint != null) {
                    this.loadImage();
                } else {
                    this.filenameData = this.$t('filehandler.no-file-selected');
                }

                if (error.response.data.error.code == 415) {
                    this.showErrorToast(this.$t('filehandler.upload-error-media-type'));
                } else if (error.response.data.error.code == 413) {
                    this.showErrorToast(this.$t('filehandler.error-fileupload-size'));
                } else {
                    this.showErrorToast(this.$t('filehandler.upload-error'));
                }

                this.$refs.fileUploader.value = null; //so you can upload a new file with the same name
            })

        },
        validateSelectedFile(file) {
            if (file == null) {
                return false;
            }

            return true;
        },
        onDelete() {
            if (this.formdata[this.field] == null) {
                return;
            }

            this.showDeleteConfirm(this.$t('basics.really-delete')).then(result => {
                if (result) {
                    this.showLoading = true;

                    var data = new FormData();
                    data.append(this.idField, this.formdata[this.idField]);
                    data.append('linkField', this.field);

                    axios.post(this.delete_endpoint + "/" + this.formdata[this.field], data)
                        .then(response => {
                            this.showLoading = false;
                            this.formdata[this.field] = null;
                            this.loadImage();
                        });
                }
            });

        },
        onDownload() {
            if (this.formdata[this.field] == null) {
                return;
            }

            this.showLoading = true;
            axios.get(this.download_endpoint + "/" + this.formdata[this.field], {
                responseType: 'blob'
            }).then(response => {
                this.showLoading = false;

                const type = response.headers['content-type'];
                const blob = new Blob([response.data], {type: type, encoding: 'UTF-8'});

                let filename = '';
                if (response.headers["content-disposition"]) {
                    filename = response.headers["content-disposition"].split("filename=")[1];
                } else if (response.headers["Content-Disposition"]) {
                    filename = response.headers["Content-Disposition"].split("filename=")[1];
                }

                filename = filename.replaceAll(' ', '');
                filename = filename.replaceAll('"', '');

                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    //IE11 and the legacy version Edge support
                    window.navigator.msSaveOrOpenBlob(blob, filename);
                } else {// other browsers
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = filename
                    link.click();
                    link.remove();
                }

            });
        },
    },
    watch: {
        getFormdataField() {
            this.loadImage();
        }
    },
    computed: {
        getFormdataField() {
            return this.formdata[this.field];
        }
    }
}
</script>

<style scoped>

.img-section {
    height: 275px;
    width: 100%;
    border: 1px solid #ced4da;
    border-radius: 0.2rem;
    background-color: #f8f8f8;
    padding: 5px
}

.img-section img {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 265px;
}

.img-section .placeholder {
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: #ced4da;
    width: 50%;
    height: 50%;
}

label {
    font-size: 16px !important;
}

.button-bar {
    margin-top: 5px;
    display: flex;
}

.button-bar div {
    margin-right: 5px;
}

label:hover {
    cursor: pointer;
}

label:disabled {
    opacity: .5;
    cursor: default;
}

.filename-section span {
    color: var(--nato-blue);
}

.invisible-fileinput {
    opacity: 0;
    width: 0;
    position: absolute;
    left: 40px
}

</style>
