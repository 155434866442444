<!--This is the first loaded Vue component, called in the vueapp.blade.php-->
<template>
    <div v-if="cookiesEnabled"
         class="app-site">
        <app-header></app-header>
        <app-menu></app-menu>
        <welcome></welcome>
        <div class="content-site-container ">
            <router-view class="content-site app-content"></router-view>
        </div>
        <app-footer></app-footer>

        <b-modal id="loading-modal" centered class="test" hide-footer hide-header no-close-on-backdrop size="sm">
            <div class="d-block text-center" style="color: black">
                <b-spinner></b-spinner>
            </div>
        </b-modal>

    </div>
    <div v-else
         class="app-site ">
        <b-alert variant="danger"
                 show
                 class="banner-container"
                 style="
                     margin: 2rem 10% 0;
                     width: 80%;
                     position: absolute;"
        >
            <p class="element1"
               style="text-align: center; margin: 0.5rem;"
            >{{ $t('login.cookie-information') }}</p>
            <b-dropdown id="langSelector"
                        class="selected-lang element2"
                        left
                        no-caret>
                <template #button-content>
                    <span class="">{{ selectedLanguage }}</span><i
                    class="fas fa-chevron-down selected-lang-caret"></i>
                </template>
                <b-dropdown-item @click="setLanguage('en')">{{ $t('header.english') }}</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="setLanguage('fr')">{{ $t('header.french') }}</b-dropdown-item>
            </b-dropdown>
        </b-alert>
    </div>
</template>

<script>

import {i18n} from "../app";

export default {
    name: "App",
    mounted() {
        if (this.cookiesEnabled) {
            const isAuthenticated = localStorage.getItem('user_access_token');
            this.setAuthentication(isAuthenticated);
        } else {
            this.initLanguage(navigator.language);
        }
    },
    data() {
        return {
            cookiesEnabled: navigator.cookieEnabled,
            selectedLanguage: 'English'
        }
    },
    methods: {
        initLanguage(locale) {
            if (locale.startsWith('fr')) {
                this.setLanguage('fr')
            } else {
                this.setLanguage('en')
            }
        },
        setLanguage(locale) {
            i18n.locale = locale;
            if (locale === 'fr') {
                this.selectedLanguage = this.$t('header.french');
            } else {
                this.selectedLanguage = this.$t('header.english');
            }

        }
    }
}
</script>

<style scoped>
.banner-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.element1 {
    flex: 1 auto auto; /* Element 1 takes as much space as needed */
    box-sizing: border-box;
    padding: 10px;
}

.element2 {
    flex: 0 0 auto; /* Element 2 is as small as possible */
    box-sizing: border-box;
    padding: 10px;
}

</style>

<style>

:root {
    --default-red: #dc3545;
    --default-green: #28a745;
    --default-orange: #FF9900;

    --default-light-red: #e6717c;
    --default-light-green: #68c17c;

    --default-light-gray: #eeeeee;
    --default-gray: #444444;
    --default-dark-gray: #333333;

    --nato-gray: #9EA5A9;
    --nato-gray2: #64696B;

    --nato-blue: #004489;
    --nato-blue-light: #0B89C6;

    --grid-background-gray: #f8f8f8;
    --grid-border-gray: #bdc3c7;
    --grid-toolbar-gray: #495057;

    /*--content-background: #f8f9fa*/
    --content-background: white;
    --form-required-field: #ffffe0;
}

body {
    font-family: 'Open Sans', sans-serif !important;
}


.nato-default-btn {
    background: #202020 !important;
    color: #fff !important;
    border: none !important;
    padding: 6px 18px 5px !important;
    border-radius: 0 !important;
}

input:focus {
    border: 2px solid #202020 !important;
    box-shadow: none !important;
}

.nato-default-btn:disabled {
    opacity: .5;
}

.nato-default-btn-red {
    background: var(--default-red) !important;
    color: #fff !important;
    border: none !important;
    padding: 6px 18px 5px !important;
    border-radius: 0 !important;
}

.nato-default-btn-blue {
    background: var(--nato-blue) !important;
    color: #fff !important;
    border: none !important;
    padding: 6px 18px 5px !important;
    border-radius: 0 !important;
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 300;
    src: local('OpenSans'),
    url('/fonts/open_sans/OpenSans-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: local('OpenSans'),
    url('/fonts/open_sans/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: local('OpenSans'),
    url('/fonts/open_sans/OpenSans-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 700;
    src: local('OpenSans'),
    url('/fonts/open_sans/OpenSans-Bold.ttf') format('truetype');
}

.success-toast {
    background-color: var(--default-light-green) !important;
    color: #fff !important;
}

.failure-toast {
    background-color: var(--default-light-red) !important;
    color: #fff !important;
}

.app-content {
    min-height: 600px;
    /*height: calc(100vh - 300px);*/
    /*height: 100%;*/
    padding: 20px 0 20px 0;
}

.hide-overflow {
    overflow: hidden;
}

.content-site-container {
    padding: 0 20px;
}

.content-site {
    margin: 0 auto !important;
    max-width: 1175px;
}

.form-section label {
    font-size: 14px;
}

.form-section input:required {
    background-color: var(--form-required-field);
}

.form-section select:required {
    background-color: var(--form-required-field);
}

.form-section input:disabled {
    background-color: #e9ecef;
    opacity: .7;
}

.form-section select:disabled {
    background-color: #e9ecef;
    opacity: .7;
}

.form-section option {
    background-color: white;
}

.tooltip-button {
    background-color: transparent !important;
    color: var(--grid-toolbar-gray);
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
    margin: 0 5px;
    font-size: 18px;
}

.tooltip-button:disabled {
    color: #ced4da !important;
    cursor: default;
}

.tooltip-button:focus {
    outline: 0 !important;
}

.tooltip-info {
    cursor: default !important;
}

#loading-modal .modal-content {
    background-color: transparent;
    border: none;
}

.modal-open {
    padding-right: 0px !important;
}

.modal-backdrop {
    background-color: #f8f9fa !important;
    opacity: 0.85 !important;
}


</style>
