<template>
    <div class="event-attendance">
        <h1>{{ $t('attendance.attendance-heading') }}</h1>
        <span v-if="!showSuccessPage">{{ $t('attendance.attendance-heading-msg') }}</span>
        <hr>

        <div v-if="showSuccessPage" class="pb-2">
            <span class="success-msg" v-html="attendanceSuccessMsg"></span>
        </div>

        <div v-if="showSuccessPage" style="text-align: center">
            <hr>
            <span class="print-page">{{ $t('attendance.printpage-msg') }}</span>

            <div>
                <b-button class="nato-default-btn" @click="goToPrint">{{ $t('attendance.print-here') }}</b-button>
            </div>

            <hr>
        </div>

        <div v-if="showSuccessPage">
            <span class="success-msg2">{{ $t('attendance.success-msg4') }}:</span><br>
        </div>

        <div class="form-section">

            <div v-if="events!=null && events.length > 0 && !showSuccessPage" class="attendance-info-msg">
                <i class="fas fa-info-circle"></i>
                <span>{{ $t('attendance.attendance-info-msg') }}</span>
            </div>

            <b-form ref="form" autocomplete="off" @submit.prevent="onSubmit">

                <div v-for="event in events" :key="event.EventID">

                    <div :class="{'event-info-active': event.AsAttendeeRegistered}"
                         class="event-info">

                        <div class="row">
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-12">
                                        <span class="eventname">{{ event.Eventname }}</span>
                                        <span class="location">{{ event.City }}</span>,&nbsp
                                        <span v-if="$i18n.locale=='en' && event.country" class="location">{{
                                                event.country.CountryName_EN
                                            }}</span>
                                        <span v-if="$i18n.locale=='fr' && event.country" class="location">{{
                                                event.country.CountryName_FR
                                            }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <span class="eventdate">{{ formatToDate(event.EventStartDate) }}</span>&nbsp-&nbsp
                                        <span class="eventdate">{{ formatToDate(event.EventEndDate) }}</span>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div v-if="event.PressRegEndDate != null && event.PressRegEndDateIgnore != 'Y'"
                                             class="regdeadline">
                                            {{ $t('attendance.registration-deadline') }}:&nbsp<span>{{
                                                formatToDateTime(event.PressRegEndDate)
                                            }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-3">
                                <div class="check-event-section">
                                    <b-form-checkbox
                                        :id="'event-checkbox-' + event.EventID"
                                        v-model="event.AsAttendeeRegistered"
                                        :disabled="showSuccessPage"
                                        class="eventcheckbox"
                                        size="lg"
                                    ></b-form-checkbox>
                                </div>

                            </div>
                        </div>


                    </div>

                    <div class="event-detail-section">
                        <b-collapse
                            :class="{'event-detail-active': event.AsAttendeeRegistered}"
                            :visible="event.AsAttendeeRegistered"
                            class="event-detail"
                        >
                            <div v-if="event.AsAttendeeRegistered">


                                <div>
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>
                                                {{
                                                    $t('attendance.witch-hotel') + '?'
                                                }}
                                            </label>
                                            <label style="font-size: 12px">
                                                {{
                                                    $t('attendance.witch-hotel-msg')
                                                }}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <b-form-select v-model="event.Attendee['PressHotelID']" :disabled="showSuccessPage"
                                                           :options="event.hotels"
                                                           size="sm"
                                                           text-field="HotelName"
                                                           value-field="PressHotelID">
                                                <template #first>
                                                    <b-form-select-option :value="null">
                                                        {{ $t('attendance.other-hotel') }}
                                                    </b-form-select-option>
                                                </template>
                                            </b-form-select>

                                        </div>
                                    </div>

                                    <hr>
                                </div>

                                <div v-if="mediaPoolcard">
                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>
                                                {{
                                                    $t('attendance.media-poolcard-required') + '?'
                                                }}
                                            </label>
                                            <label style="font-size: 12px">
                                                {{
                                                    $t('attendance.media-poolcard-msg')
                                                }}
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <b-form-checkbox
                                                v-model="event.Attendee['MediaPoolCardRequired_YN']"
                                                :disabled="showSuccessPage"
                                                switch
                                                unchecked-value="N"
                                                value="Y">
                                            </b-form-checkbox>
                                        </div>
                                    </div>

                                    <hr>

                                </div>


                                <div v-if="event.DocUploadYN == 'Y'">

                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>
                                                {{
                                                    $t('attendance.passport-upload')
                                                }}
                                                <span v-if="event.PassportRequired == 'Y'"><b>*</b></span>
                                            </label>

                                            <div>
                                                <span class="file-info-label">[Max. {{
                                                        maxFileSize
                                                    }}MB / ' jpg, pdf ']</span>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <file-handler
                                                :ref="'passportUploader' + event.EventID"
                                                :disabled="showSuccessPage"
                                                :eventId="event.EventID"
                                                :formdata="event.Attendee"
                                                :initialFilename="event.Attendee['PassportUploadName']"
                                                :required="event.PassportRequired == 'Y'"
                                                :showButtons="true"
                                                :showDelete="false"
                                                :showDownload="true"
                                                :showImage="false"
                                                acceptTypes=".jpg, .jpeg, .pdf"
                                                download_endpoint="/event/download"
                                                field="PassportUpload"
                                                idField="MasterdataID"
                                                placeholder="fa-user-circle"
                                                upload_endpoint="/event/upload-passport"
                                            >
                                            </file-handler>
                                        </div>
                                    </div>

                                    <hr>

                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>
                                                {{
                                                    $t('attendance.letter-upload1')
                                                }}
                                                <br>
                                                <b>
                                                    {{
                                                        $t('attendance.or')
                                                    }}
                                                </b>
                                                <br>
                                                {{
                                                    $t('attendance.letter-upload2')
                                                }}
                                                <span v-if="event.LetterRequired == 'Y'"><b>*</b></span>
                                            </label>

                                            <div class="file-info-label">
                                                <span>{{ $t('attendance.freelancers-contact') }}</span>&nbsp;
                                                <a :href="'mailto:'+natoOfficeMail">{{
                                                        $t('attendance.nato-press-office')
                                                    }}</a>
                                            </div>

                                            <div>
                                                <span class="file-info-label">[Max. {{
                                                        maxFileSize
                                                    }}MB / ' jpg, pdf ']</span>
                                            </div>
                                        </div>
                                        <div class="col-md-9">
                                            <file-handler
                                                :ref="'letterUploader' + event.EventID"
                                                :disabled="showSuccessPage"
                                                :eventId="event.EventID"
                                                :formdata="event.Attendee"
                                                :initialFilename="event.Attendee['LetterUploadName']"
                                                :required="event.LetterRequired == 'Y'"
                                                :showButtons="true"
                                                :showDelete="false"
                                                :showDownload="true"
                                                :showImage="false"
                                                acceptTypes=".jpg, .jpeg, .pdf"
                                                download_endpoint="/event/download"
                                                field="LetterUpload"
                                                idField="MasterdataID"
                                                placeholder="fa-user-circle"
                                                upload_endpoint="/event/upload-letter"
                                            >
                                            </file-handler>
                                        </div>
                                    </div>
                                    <hr>
                                </div>

                                <div v-if="event.typeOfPressEvent == 'Other'">

                                    <div class="row">
                                        <div class="col-md-3">
                                            <label>
                                                {{
                                                    $t('attendance.select-one')
                                                }}
                                                <span><b>*</b></span>
                                            </label>
                                        </div>
                                        <div class="col-md-9">
                                            <b-form-checkbox v-for="option in [1,2,3,4,5]" v-if="event['option'+ option + '_YN'] == 'Y'"
                                                             :key="option"
                                                             v-model="event.Attendee['portal_option'+option+'_YN']"
                                                             :disabled="showSuccessPage"
                                                             :name="event.EventID + 'portal_option'+option"
                                                             :required="!(event.Attendee['portal_option1'+'_YN'] == 'Y' || event.Attendee['portal_option2'+'_YN'] == 'Y' || event.Attendee['portal_option3'+'_YN'] == 'Y' || event.Attendee['portal_option4'+'_YN'] == 'Y' || event.Attendee['portal_option5'+'_YN'] == 'Y')"
                                                             class="option-checkbox"
                                                             switch
                                                             unchecked-value="N"
                                                             value="Y">
                                                {{
                                                    $i18n.locale == 'en' ? event['option' + option + '_EN'] : event['option' + option + '_FR']
                                                }}
                                            </b-form-checkbox>
                                        </div>
                                    </div>

                                    <hr>

                                    <div>
                                        <div v-for="option in [1,2,3]" v-if="event['freeText'+ option + '_YN'] == 'Y'" class="row"
                                             style="margin-bottom: 10px">
                                            <div class="col-md-3">
                                                <label>
                                                    {{
                                                        $i18n.locale == 'en' ? event['freeText' + option + '_EN'] : event['freeText' + option + '_FR']
                                                    }}
                                                    <span><b>*</b></span>
                                                </label>
                                            </div>
                                            <div class="col-md-9">
                                                <b-input v-model="event.Attendee['portal_freeText'+option]" :disabled="showSuccessPage" required
                                                         size="sm"></b-input>
                                            </div>
                                        </div>
                                    </div>

                                    <hr>

                                    <div>
                                        <div v-for="option in [1,2]" v-if="event['disclaimer'+ option + '_YN'] == 'Y'"
                                             class="row">
                                            <div class="col-md-3">
                                                <label>
                                                    {{
                                                        $i18n.locale == 'en' ? event['disclaimer' + option + '_EN'] : event['disclaimer' + option + '_FR']
                                                    }}
                                                    <span><b>*</b></span>
                                                </label>
                                            </div>
                                            <div class="col-md-9">
                                                <b-form-checkbox
                                                    v-model="event.Attendee['portal_disclaimer'+option+'_YN']"
                                                    :disabled="showSuccessPage"
                                                    :name="event.EventID + 'portal_disclaimer'+option"
                                                    required
                                                    switch
                                                    unchecked-value="N"
                                                    value="Y">
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </b-collapse>
                    </div>

                </div>

                <div v-if="noEvents">

                    <div v-if="!showSuccessPage">
                        <span class="noevents">{{ $t('attendance.no-events') }}</span>
                    </div>

                    <div v-if="showSuccessPage">
                        <span class="noevents">{{ $t('attendance.no-events2') }}</span>
                    </div>


                </div>

                <hr>

                <div v-if="!showSuccessPage">
                    <div>
                        <b-form-checkbox
                            name="accept_data"
                            required
                            switch
                        >
                            {{ $t('attendance.agree-deny-accreditation-msg') }}
                            <span><b>*</b></span>
                        </b-form-checkbox>
                    </div>
                    <div style="text-align: right">
                        <b-button ref="submit" :disabled="events == null || events.length == 0" class="nato-default-btn"
                                  size="lg" type="submit" value="submit">
                            {{ $t('attendance.submit') }}
                        </b-button>
                    </div>
                </div>

                <div v-if="showSuccessPage" style="text-align: right">
                    <b-button class="nato-default-btn" @click="onEditEvents">{{ $t('basics.edit') }}</b-button>
                </div>

            </b-form>
        </div>

    </div>
</template>

<script>
export default {
    name: "EventAttendance",
    data() {
        return {
            events: null,
            noEvents: false,
            maxFileSize: 9,
            natoOfficeMail: '',
            mediaPoolcard: false,
            showSuccessPage: false,
            attendanceSuccessMsg: '',
        }
    },
    created() {
        this.loadEvents();
        this.maxFileSize = get_MIX_ATTENDANCE_MAX_FILESIZE();
        this.natoOfficeMail = get_MIX_NATO_OFFICE_MAIL();

        axios
            .get('/templates/' + this.$t('attendance.success-msg-filename'), {baseURL: '/'})
            .then(response => this.attendanceSuccessMsg = response.data);
    },
    methods: {
        loadEvents() {
            this.noEvents = false;
            this.events = null;
            this.showLoadingApp(true);
            axios.get('/event/available-events').then(response => {
                this.showLoadingApp(false);
                this.events = response.data.data.events
                this.mediaPoolcard = response.data.data.mediaPoolcard;
                if (this.events.length == 0) {
                    this.noEvents = true;
                    return;
                }

            })
        },
        onSubmit(event) {
            this.showLoadingApp(true);
            axios.post('/event/save-attendance', {
                'events': this.events
            }).then(response => {
                window.scrollTo(0, 0);
                this.showLoadingApp(false);
                this.showSuccessPage = true;
                this.events = response.data.data.events
                this.mediaPoolcard = response.data.data.mediaPoolcard;
                if (this.events.length == 0) {
                    this.noEvents = true;
                    return;
                }
            }).catch(error => {
                this.showErrorToast(this.$t('basics.fail-saved'), 5000);
                this.showSuccessPage = false;
            });
        },
        onEditEvents() {
            this.appliedEvents = [];
            this.showSuccessPage = false;
            this.loadEvents();
        },
        goToPrint() {
            this.$router.push('/home/events');
        },
    }
}
</script>

<style scoped>

.event-attendance {
    padding-bottom: 150px;
}

.event-info {
    background-color: #202020;
    color: white;
    padding: 10px;
}

.event-info-active {
    color: white;
    background-color: var(--nato-blue-light);
}

.event-detail-section {
    margin-bottom: 20px;
}

.event-detail {
    border: 1px solid #202020;
    padding: 10px;
}

.event-detail-active {
    border: 1px solid var(--nato-blue-light);
}

.eventname {
    font-size: 25px;
    font-weight: bold;
}

.location {
    font-size: 20px;
}

.eventdate {
    font-size: 16px;
}

.regdeadline {
    font-size: 12px;
}

.check-event-section {
    position: absolute;
    top: calc(50% - 20px);
    right: 20px;
}

/deep/ .eventcheckbox .custom-control-label::before {

    border-radius: 0 !important;
    width: 1.85rem !important;
    height: 1.85rem !important;
}


/deep/ .eventcheckbox .custom-control-label::after {
    cursor: pointer !important;
    width: 1.85rem !important;
    height: 1.85rem !important;
}

/deep/ .eventcheckbox .custom-control-input:checked ~ .custom-control-label::after {
    background-color: var(--nato-blue) !important;
    border-color: var(--nato-blue) !important;
}

/deep/ .eventcheckbox .custom-control-input:disabled {
    opacity: 0 !important;
}

.noevents {
    color: gray;
    font-size: 30px;
}

.option-checkbox {
    margin-bottom: 5px;
}

.file-info-label {
    font-size: 12px;
}

.attendance-info-msg {
    margin-bottom: 10px;
}

.attendance-info-msg span {
    font-size: 12px;
}

/deep/ input[type="checkbox"]:disabled {
    opacity: 0 !important;
}

.success-msg {
    color: green;
}

.success-msg2 {
    color: black;
    font-size: 16px;
}

.print-page {
    color: red;
    font-size: 20px;
    font-weight: bold;
}

</style>
