<template>
    <div class="events">
        <h1>{{ $t('event.heading') }}</h1>
        <span>{{ $t('attendance.printpage-msg') }}</span>
        <hr>

        <div class="form-section">

            <div v-if="events!=null && events.length > 0" class="attendance-info-msg">
                <i class="fas fa-info-circle"></i>
                <span>{{ $t('event.print-event-msg') }}</span>
            </div>

            <div v-for="event in events">

                <div :class="{'event-info-not-active': !event.printable}" class="event-info event-info-active">

                    <div class="row">
                        <div class="col-9">
                            <div class="row">
                                <div class="col-12">
                                    <span class="eventname">{{ event.Eventname }}</span>
                                    <span class="location">{{ event.City }}</span>,&nbsp
                                    <span v-if="$i18n.locale=='en' && event.country" class="location">{{
                                            event.country.CountryName_EN
                                        }}</span>
                                    <span v-if="$i18n.locale=='fr' && event.country" class="location">{{
                                            event.country.CountryName_FR
                                        }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <span class="eventdate">{{ formatToDate(event.EventStartDate) }}</span>&nbsp-&nbsp
                                    <span class="eventdate">{{ formatToDate(event.EventEndDate) }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-12">
                                    <div v-if="event.PressRegEndDate != null && event.PressRegEndDateIgnore != 'Y'"
                                         class="regdeadline">
                                        {{ $t('attendance.registration-deadline') }}:&nbsp<span>{{
                                            formatToDateTime(event.PressRegEndDate)
                                        }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="col-3">
                            <div v-if="event.printable" class="check-event-section">
                                <b-button class="nato-default-btn" @click="onPrint(event)">
                                    {{ $t('event.print-button') }} <i class="fas fa-print"></i></b-button>
                            </div>
                            <div v-if="!event.printable" v-b-tooltip.hover :title="$t('attendance.wait-acceptance')"
                                 class="check-event-section">
                                <b-button class="nato-default-btn" disabled @click="">{{ $t('event.print-button') }} <i
                                    class="fas fa-lock"></i></b-button>
                            </div>
                        </div>
                    </div>


                </div>

            </div>

            <div v-if="noEvents">
                <span class="noevents">{{ $t('attendance.no-events2') }}</span>
            </div>

        </div>

    </div>
</template>

<script>

import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
    name: "Events",
    data() {
        return {
            events: null,
            noEvents: false,
        }
    },
    created() {
        this.loadEvents();
    },
    methods: {
        loadEvents() {
            this.noEvents = false;
            this.events = null;
            this.showLoadingApp(true);
            axios.get('/event/applied-events').then(response => {
                this.showLoadingApp(false);
                this.events = response.data.data;
                if (this.events.length == 0) {
                    this.noEvents = true;
                    return;
                }
            });
        },
        formatToDate(value) {
            if (value) {
                if (this.$i18n.locale == 'en') {
                    return this.$moment(String(value)).format('MMMM DD, YYYY')
                } else {
                    return this.$moment(String(value)).format('DD MMMM YYYY')
                }
            }
            return value;
        },
        formatToDateTime(value) {
            if (value) {
                if (this.$i18n.locale == 'en') {
                    return this.$moment(String(value)).format('MMMM DD, YYYY HH:mm')
                } else {
                    return this.$moment(String(value)).format('DD MMMM YYYY HH:mm')
                }
            }
            return value;
        },
        onPrint(event) {
            this.showLoadingApp(true);
            axios.get('/event/print-event' + '?eventId=' + event.EventID).then(response => {
                let data = response.data.data;
                if (data) {
                    this.generatePDF(data);
                }
                this.showLoadingApp(false);
            }).catch((error) => {
                let errors = error.response.data.error.message;
                this.showLoadingApp(false);
                this.showErrorToast(errors, 5000);
            });
        },
        generatePDF(data) {

            let event = data.events[0];

            if (event)
                this.setNullValuesToEmptyString(event);
            if (event.Attendee)
                this.setNullValuesToEmptyString(event.Attendee);
            if (data.attendee)
                this.setNullValuesToEmptyString(data.attendee);

            let doc = new jsPDF();
            let finalY = 15;
            let finalX = 15;

            // let pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
            let pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();

            doc.setFontSize(18);
            doc.text("Event attendance report",
                finalX,
                finalY);

            doc.setFontSize(9);
            doc.text(this.formatToDate(new Date().toISOString()), pageWidth - finalX, finalY, {
                align: 'right'
            });

            doc.addImage("/images/logo/logo.png", "PNG", pageWidth - finalX - 30, finalY += 5, 30, 15);

            doc.setFontSize(8);
            doc.setFont('default', 'italic');
            doc.setTextColor(255, 0, 0);
            doc.text('Please print this document and bring it to the event with you', finalX, finalY += 3);
            doc.setTextColor(0, 0, 0);
            doc.setFont('default', 'normal');
            doc.setFontSize(9);


            doc.setFontSize(12);

            //GENERAL EVENT INFORMATION------------------------

            doc.setFont('default', 'bold');
            doc.text('Event information', finalX, finalY += 10);
            doc.setFont('default', 'normal');
            doc.line(finalX, finalY += 2, finalX + 85, finalY);

            doc.text('Event:', finalX, finalY += 5);
            doc.text(event.Eventname, finalX + 60, finalY);

            doc.text('Location:', finalX, finalY += 5);
            let eventCountry = ''
            if (event.country) {
                eventCountry = ', ' + event.country.CountryName_EN
            }
            doc.text(event.City + eventCountry, finalX + 60, finalY);

            doc.text('Event date:', finalX, finalY += 5);
            doc.text(this.formatToDate(event.EventStartDate) +
                ' - ' + this.formatToDate(event.EventEndDate), finalX + 60, finalY);

            //PERSONAL INFORMATION------------------------

            doc.setFont('default', 'bold');
            doc.text('Personal information', finalX, finalY += 10);
            doc.setFont('default', 'normal');
            doc.line(finalX, finalY += 2, finalX + 85, finalY);

            let photoData = data.attendee.photoContent;
            if (photoData) {
                doc.addImage(photoData, 'JPEG', pageWidth - finalX - 40, finalY, 40, 0);
            }

            doc.text('ID:', finalX, finalY += 5);
            doc.text(data.attendee.MasterdataID, finalX + 60, finalY);

            doc.text('Name:', finalX, finalY += 5);
            doc.text(data.attendee.Firstname + ' ' + data.attendee.Lastname, finalX + 60, finalY);

            doc.text('Address:', finalX, finalY += 5);
            doc.text(data.attendee.PrivStreet + ' ' + data.attendee.PrivHouseNumber, finalX + 60, finalY);
            doc.text('', finalX, finalY += 5);
            doc.text(data.attendee.PrivZIP + ' ' + data.attendee.PrivCity, finalX + 60, finalY);
            doc.text('', finalX, finalY += 5);
            if (data.attendee.country) {
                doc.text(data.attendee.country.CountryName_EN, finalX + 60, finalY);
            } else {
                doc.text('', finalX + 60, finalY);
            }

            doc.text('Email:', finalX, finalY += 5);
            doc.text(data.attendee.EMail, finalX + 60, finalY);

            doc.text('Phone:', finalX, finalY += 5);
            doc.text(data.attendee.PrivTelephone, finalX + 60, finalY);

            doc.text('Fax:', finalX, finalY += 5);
            doc.text(data.attendee.PrivFax, finalX + 60, finalY);

            doc.text('Mobile:', finalX, finalY += 5);
            doc.text(data.attendee.PrivMobile, finalX + 60, finalY);

            doc.text('Date of birth:', finalX, finalY += 5);
            doc.text(this.formatToDate(data.attendee.DateOfBirth), finalX + 60, finalY);

            doc.text('Place of birth:', finalX, finalY += 5);

            let countryOfBirth = '';
            if (data.attendee.country_of_birth) {
                countryOfBirth = ', ' + data.attendee.country_of_birth.CountryName_EN;
            }
            doc.text(data.attendee.PlaceOfBirth + countryOfBirth, finalX + 60, finalY);

            doc.text('Nationality:', finalX, finalY += 5);
            if (data.attendee.nationality) {
                doc.text(data.attendee.nationality.CountryName_EN, finalX + 60, finalY);
            } else {
                doc.text('', finalX + 60, finalY);
            }

            doc.setFont('default', 'bold');
            doc.text('Offical information', finalX, finalY += 10);
            doc.setFont('default', 'normal');
            doc.line(finalX, finalY += 2, finalX + 85, finalY);

            doc.text('Identification:', finalX, finalY += 5);
            if (data.attendee.identification) {
                doc.text(data.attendee.identification.SysValue_EN, finalX + 60, finalY);
            } else {
                doc.text('', finalX + 60, finalY);
            }

            doc.text('Identity number:', finalX, finalY += 5);
            doc.text(data.attendee.PassportNo, finalX + 60, finalY);

            doc.text('Issued by:', finalX, finalY += 5);
            doc.text(data.attendee.PassportIssuedBy, finalX + 60, finalY);

            doc.text('National Press card:', finalX, finalY += 5);
            doc.text(data.attendee.NationalPressCardNumber, finalX + 60, finalY);

            doc.text('Issued by:', finalX, finalY += 5);
            doc.text(data.attendee.NationalPressCardIssuedBy, finalX + 60, finalY);

            doc.setFont('default', 'bold');
            doc.text('Media information', finalX, finalY += 10);
            doc.setFont('default', 'normal');
            doc.line(finalX, finalY += 2, finalX + 85, finalY);

            doc.text('Organization:', finalX, finalY += 5);
            let orgaCountry = ''
            if (data.attendee.orga_country) {
                orgaCountry = ', ' + data.attendee.orga_country.CountryName_EN
            }
            doc.text(data.attendee.Company + orgaCountry, finalX + 60, finalY);

            doc.text('Type of media:', finalX, finalY += 5);
            if (data.attendee.type_of_media && data.attendee.type_of_media !== 0) {
                doc.text(data.attendee.type_of_media.SysValue_EN, finalX + 60, finalY);
            } else {
                doc.text('Other, ' + data.attendee.TypeOfMediaOther, finalX + 60, finalY);
            }

            doc.text('Qualification:', finalX, finalY += 5);
            if (data.attendee.qualification) {
                doc.text(data.attendee.qualification.SysValue_EN, finalX + 60, finalY);
            } else {
                doc.text('', finalX + 60, finalY);
            }


            //ATTENDANCE INFORMATION------------------------

            doc.setFont('default', 'bold');
            doc.text('Attendance information', finalX, finalY += 10);
            doc.setFont('default', 'normal');
            doc.line(finalX, finalY += 2, finalX + 85, finalY);

            doc.text('Hotel:', finalX, finalY += 5);
            if (data.attendee.presshotel) {
                doc.text(data.attendee.presshotel.HotelName, finalX + 60, finalY);
            } else {
                doc.text('Other', finalX + 60, finalY);
            }

            if (data.mediaPoolcard) {
                doc.text('Media pool card:', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.MediaPoolCardRequired_YN), finalX + 60, finalY);
            }

            if (event.option1_YN == 'Y') {
                doc.text(event.option1_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_option1_YN), finalX + 60, finalY);
            }
            if (event.option2_YN == 'Y') {
                doc.text(event.option2_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_option2_YN), finalX + 60, finalY);
            }
            if (event.option3_YN == 'Y') {
                doc.text(event.option3_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_option3_YN), finalX + 60, finalY);
            }
            if (event.option4_YN == 'Y') {
                doc.text(event.option4_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_option4_YN), finalX + 60, finalY);
            }
            if (event.option5_YN == 'Y') {
                doc.text(event.option5_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_option5_YN), finalX + 60, finalY);
            }

            if (event.freeText1_YN == 'Y') {
                doc.text(event.freeText1_EN + ':', finalX, finalY += 5);
                doc.text(event.Attendee.portal_freeText1, finalX + 60, finalY);
            }
            if (event.freeText2_YN == 'Y') {
                doc.text(event.freeText2_EN + ':', finalX, finalY += 5);
                doc.text(event.Attendee.portal_freeText2, finalX + 60, finalY);
            }
            if (event.freeText3_YN == 'Y') {
                doc.text(event.freeText3_EN + ':', finalX, finalY += 5);
                doc.text(event.Attendee.portal_freeText3, finalX + 60, finalY);
            }

            if (event.disclaimer1_YN == 'Y') {
                doc.text(event.disclaimer1_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_disclaimer1_YN), finalX + 60, finalY);
            }
            if (event.disclaimer2_YN == 'Y') {
                doc.text(event.disclaimer2_EN + ':', finalX, finalY += 5);
                doc.text(this.formatYNToYesNo(event.Attendee.portal_disclaimer2_YN), finalX + 60, finalY);
            }

            this.saveDoc(doc);

        },
        saveDoc(doc) {
            // download pdf if IE is used
            if (this.msieVersion()) {
                doc.save('nato_event.pdf');
            } else {
                doc.setProperties({
                    title: 'nato_event.pdf'
                });
            }
            doc.output('dataurlnewwindow', 'nato_event.pdf');
            doc.save('nato_event.pdf');

        },
        msieVersion() {
            const userAgent = window.navigator.userAgent;
            const msie = userAgent.indexOf("MSIE ");
            return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
        },
        setNullValuesToEmptyString(obj) {
            Object.keys(obj).forEach(function (key) {
                if (obj[key] === null) {
                    obj[key] = '';
                }
            });
        },
        formatYNToYesNo(val) {
            if (val && val == 'Y') {
                return 'Yes';
            } else {
                return 'No';
            }
        }

    },

}
</script>

<style scoped>

.events {
    padding-bottom: 150px;
}

.event-info {
    background-color: #202020;
    color: white;
    padding: 10px;
}

.event-info-active {
    color: white;
    background-color: var(--nato-blue-light);
    margin-bottom: 20px;
}

.event-info-not-active {
    background-color: var(--nato-gray) !important;
}

.eventname {
    font-size: 25px;
    font-weight: bold;
}

.location {
    font-size: 20px;
}

.eventdate {
    font-size: 16px;
}

.regdeadline {
    font-size: 12px;
}

.check-event-section {
    position: absolute;
    top: calc(50% - 18px);
    right: 20px;
}


.noevents {
    color: gray;
    font-size: 30px;
}

.attendance-info-msg {
    margin-bottom: 10px;
}

.attendance-info-msg span {
    font-size: 12px;
}


</style>
