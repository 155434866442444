<template>
    <div class="app-footer">
        <div class="app-footer-inner">
            <div class="version-section">
                <p><small> {{ $t('general.version') }} {{ this.app_version }}</small></p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {
            app_version: this.getVersion()
        }
    },
}
</script>

<style scoped>

/*.app-footer {*/
/*    padding: 20px 0 20px 0;*/
/*    background-color: black;*/
/*    color: white;*/
/*}*/

/*.app-footer-inner {*/
/*    margin: 0 auto;*/
/*    max-width: 1175px;*/
/*    text-align: center;*/
/*}*/

.version-section {
    padding: 20px;
}

</style>
