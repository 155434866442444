<template>
    <div class="content-site-container">
        <div class="content-site app-menu">

            <div v-if="!isAuthenticated" class="menu">
                <router-link :to="{ path: '/' }">
                    {{ $t('menu.home') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/login' }">
                    {{ $t('menu.login') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/register' }">
                    {{ $t('menu.register') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/forgot-password' }">
                    {{ $t('menu.forgot-password') }}
                </router-link>

                <div class="vertical-line"></div>

                <a :href="accrProcedure" target="_blank">
                    {{ $t('menu.media-accreditation-procedures') }}
                </a>

            </div>

            <div v-if="isAuthenticated" class="menu">
                <router-link :to="{ path: '/home' }">
                    {{ $t('menu.home') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/home/event-attendance' }">
                    {{ $t('menu.event-attendance') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/home/events' }">
                    {{ $t('menu.events') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/home/personal-data' }">
                    {{ $t('menu.personal-data') }}
                </router-link>

                <div class="vertical-line"></div>

                <router-link :to="{ path: '/home/annual-accreditation' }">
                    {{ $t('menu.annual-accreditation') }}
                </router-link>

                <div class="vertical-line"></div>

                <a :href="accrProcedure" target="_blank">
                    {{ $t('menu.media-accreditation-procedures') }}
                </a>
            </div>

            <div class="mobile-menu">
                <div class="burger-menu" @click="openMenu=!openMenu">
                    <i class="fas fa-bars"></i>
                </div>
                <div :class="{'mobile-menu-active': openMenu}" class="mobile-menu-section">
                    <div class="mobile-menu-header">
                        <span>{{ $t('menu.menu') }}</span>

                        <div style="cursor: pointer;float: right" @click="openMenu = !openMenu">
                            <i class="fas fa-bars"></i>
                        </div>
                    </div>
                    <div class="mobile-menu-content">
                        <div v-if="!isAuthenticated" class="">
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/' }">
                                    {{ $t('menu.home') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/login' }">
                                    {{ $t('menu.login') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/register' }">
                                    {{ $t('menu.register') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/forgot-password' }">
                                    {{ $t('menu.forgot-password') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <a :href="accrProcedure" target="_blank">
                                    {{ $t('menu.media-accreditation-procedures') }}
                                </a>
                            </div>

                        </div>

                        <div v-if="isAuthenticated" class="">
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/home' }">
                                    {{ $t('menu.home') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/home/event-attendance' }">
                                    {{ $t('menu.event-attendance') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/home/events' }">
                                    {{ $t('menu.events') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/home/personal-data' }">
                                    {{ $t('menu.personal-data') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <router-link :to="{ path: '/home/annual-accreditation' }">
                                    {{ $t('menu.annual-accreditation') }}
                                </router-link>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <a :href="accrProcedure" target="_blank">
                                    {{ $t('menu.media-accreditation-procedures') }}
                                </a>
                            </div>
                            <div @click="openMenu = !openMenu">
                                <a href="#" @click="logout">
                                    {{ $t('header.logout') }}
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import {eventBus} from "../../event-bus/event-bus";

export default {
    name: "Menu",
    data() {
        return {
            isAuthenticated: false,
            openMenu: false,
            accrProcedure: null
        }
    },
    created() {
        eventBus.$on('auth', value => {
            this.isAuthenticated = value;
        });
        this.accrProcedure = get_MIX_NATO_ACCREDITATION_PROCEDURE();
    },
    methods: {
        logout() {
            this.onLogout();
        }
    }
}
</script>

<style scoped>

.app-menu {
    margin-top: 50px !important;
    margin-bottom: 15px !important;
}

@media (max-width: 680px) {
    .menu {
        display: none !important;
    }

    .mobile-menu {
        display: inherit !important;
    }
}

.mobile-menu {
    display: none;
}

.burger-menu {
    font-size: 20px;
    cursor: pointer;
}

.mobile-menu-section {
    width: 240px;
    height: 100%;
    position: fixed;
    left: -240px;
    top: 0;
    z-index: 9999;
    background-color: black;
    color: white;
    transition: left 0.4s;
}

.mobile-menu-active {
    left: 0;
}

.mobile-menu-header {
    background-color: var(--nato-blue);
    padding: 10px;
    font-size: 20px;
}

.mobile-menu-content {
    padding: 10px;
}

.mobile-menu-content div {
    margin-bottom: 20px;
}

.mobile-menu-content a {
    color: white;
    text-decoration: none;
    font-size: 17px;
    width: 100%;
    display: block;
}

.menu {
    margin: 0px;
    padding: 0;
    list-style-type: none;
    list-style: none;
    display: flex;
}

.menu a {
    margin-right: 20px;
    line-height: 1.75;
    text-transform: uppercase;
    color: #212121;
    font-weight: 600;
    text-decoration: none;
    border-bottom: 5px solid transparent;
}

.menu a:not(:first-child) {
    margin-left: 20px;
}

.menu a:hover {
    border-bottom: 5px solid #1b7fcc;
}

.vertical-line {
    width: 1px;
    background-color: #CBCBCB;
    height: 15px;
    margin-top: 5px;
}

</style>
