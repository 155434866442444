<template>
    <div>
        <div>
            <h1>{{ $t('home2.heading') }}</h1>
            <span>{{ $t('home2.home-msg') }}</span>
            <hr>
        </div>

        <div class="goto-section">
            <div class="row">
                <div class="col-md-4">
                    <div class="pane">
                        <router-link :to="{ path: '/home/event-attendance' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-calendar"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.event-attendance') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pane">
                        <router-link :to="{ path: '/home/events' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-print"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.events') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pane">
                        <router-link :to="{ path: '/home/personal-data' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-address-card"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.personal-data') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="pane">
                        <router-link :to="{ path: '/home/annual-accreditation' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-calendar-check"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.annual-accreditation') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pane">
                        <a :href="accrProcedure" target="_blank">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-question"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('home.help') }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pane" @click="onLogout">
                        <a href="#">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-sign-out-alt"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('header.logout') }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "IndexLoggedIn",
    data() {
        return {
            accrProcedure: null
        }
    },
    created() {
        this.accrProcedure = get_MIX_NATO_ACCREDITATION_PROCEDURE()
    },
}
</script>

<style scoped>

</style>
