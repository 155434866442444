<template>
    <div class="content-site-container">
        <div class="content-site app-header row">

            <div class="col-xl-9 col-md-8 col-3" style="padding: 0">
                <a href="https://www.nato.int/" target="_blank">
                    <img class="nato-logo" src="/images/logo/logo_complete.png">
                    <img class="nato-logo-small" src="/images/logo/logo.png">
                </a>
            </div>

            <div class="header-menu col-xl-3 col-md-4 col-9" style="padding: 0">
                <b-button v-if="isAuthenticated" class="nato-default-btn logout-btn" size="sm" type="button"
                          @click="logout">
                    {{ $t('header.logout') }} <i class="fas fa-sign-out-alt"></i>
                </b-button>

                <b-dropdown id="langSelector" class="selected-lang" left no-caret>
                    <template #button-content>
                        <span class="">{{ selectedLanguage }}</span><i
                        class="fas fa-chevron-down selected-lang-caret"></i>
                    </template>
                    <b-dropdown-item @click="setLanguage('en', 'English')">{{ $t('header.english') }}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="setLanguage('fr', 'French')">{{ $t('header.french') }}</b-dropdown-item>
                </b-dropdown>

            </div>

        </div>

    </div>
</template>

<script>
import {eventBus} from "../../event-bus/event-bus";
import {i18n} from "../../app";

export default {
    name: "Header",
    data() {
        return {
            isAuthenticated: false,
            selectedLanguage: 'Language',
        }
    },
    methods: {
        logout() {
            this.onLogout();
        },
        setLanguage(locale, langName) {
            this.selectedLanguage = langName;
            i18n.locale = locale;
            localStorage.setItem("language", locale);
            // axios.post('user/update-language', {
            //     language: locale
            // }).then(response => {
            // })
            window.location.reload();
        },
    },
    created() {
        eventBus.$on('auth', value => {
            this.isAuthenticated = value;
        });

        if (localStorage.getItem("language") == 'fr') {
            i18n.locale = 'fr';
            this.selectedLanguage = this.$t('header.french');
        } else {
            i18n.locale = 'en';
            this.selectedLanguage = this.$t('header.english');
        }

    }

}
</script>

<style scoped>

.app-header {
    margin-top: 20px !important;
}

.nato-logo {
    width: 100%;
    max-width: 718px;
}

.nato-logo-small {
    display: none;
}

@media (max-width: 780px) {
    .nato-logo {
        display: none;
    }

    .nato-logo-small {
        display: inline;
        max-height: 80px;
    }
}

.header-menu {
    text-align: right;
}


/deep/ #langSelector__BV_toggle_ {
    background-color: transparent !important;
    box-shadow: none;
    color: #212121;
    font-size: 15px;
    border: none !important;
    padding-bottom: 0;
    margin-top: -5px;
}


.selected-lang-caret {
    margin-left: 20px;
}

/deep/ #langSelector .dropdown-menu {
    border-radius: 0 !important;
    padding: 0;
    line-height: 1em;
    font-size: 15px;
    min-width: 108px;
}

/deep/ #langSelector .dropdown-item {
    color: #0080CC;
    line-height: 1em;
    padding: 10px;
}

/deep/ #langSelector a:hover {
    color: black !important;
}

/deep/ #langSelector hr {
    margin: 0 0;
}

@media (max-width: 680px) {
    .logout-btn {
        display: none;
    }
}

</style>
