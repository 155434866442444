<template>
    <div class="annual-acc-section">
        <h1>{{ $t('annual.heading') }}</h1>
        <span>{{ $t('annual.annual-info') }}</span>
        <hr>

        <div class="help-info-section">
            <i class="fas fa-info-circle"></i>
            <span>{{ $t('annual.help') }}</span>
            <a :href="accrProcedure" target="_blank">
                <span>{{ $t('menu.media-accreditation-procedures') }}</span>
            </a>
        </div>

        <div v-if="!annualAccEnabled">
            <span class="onlyForBelgium">{{ $t('annual.onlyForBelgium') }}</span>
        </div>

        <div v-if="annualAccEnabled">
            <div class="help-info-section">
                <i class="fas fa-info-circle"></i>
                <span>{{ $t('annual.changes-data') }}</span>
                <router-link :to="{ path: '/home/personal-data' }">
                    <span>{{ $t('menu.personal-data') }}</span>
                </router-link>
            </div>

            <br>

            <div>
                <span style="cursor: pointer" @click="onNewRequest">
                    <i class="fas fa-calendar-plus"></i>
                    <span>{{ $t('annual.create-new-request') }}</span>
                 </span>
            </div>

            <hr>

            <div>
                <div v-for="accreditation in list">

                    <div :style="'background-color:' + getAnnualStateColor(accreditation.State)"
                         class="event-info event-info-active"
                    >

                        <div class="row">
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-12">
                                        <span>{{ $t('annual.acc-request') }}</span>
                                        <div>
                                            <span>{{ $t('annual.state') }}: </span>
                                            <b><span>{{ getAnnualStateText(accreditation.State) }}</span></b>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <b>
                                            <span class="eventdate">{{
                                                    formatToDate(accreditation.config.PeriodStartDate)
                                                }}</span>&nbsp-&nbsp
                                            <span class="eventdate">{{
                                                    formatToDate(accreditation.config.PeriodEndDate)
                                                }}</span>
                                        </b>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div v-if="accreditation.config.RegistrationEndDate != null"
                                             class="regdeadline">
                                            {{ $t('annual.reg-deadline') }}:&nbsp<span>{{
                                                formatToDateTime(accreditation.config.RegistrationEndDate)
                                            }}</span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div class="col-3">
                                <div class="check-event-section">
                                    <b-button class="nato-default-btn" @click="onEditRequest(accreditation.id)">
                                        {{ $t('annual.edit-request') }} <i class="fas fa-edit"></i>
                                    </b-button>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>


            </div>

            <b-modal id="modal-new-request"
                     :cancel-title="$t('annual.cancel-new')"
                     :ok-disabled="!selectedNew"
                     :ok-title="$t('annual.continue-new')"
                     :title="$t('annual.new-title')"
                     buttonSize="sm"
                     centered
                     @ok="goToDetail">

                <b-overlay :show="showModalLoading" class="h-100" rounded="sm">
                    <div>
                        <b><span>{{ $t('annual.current-title') }}: </span></b>

                        <div v-if="noPeriods">
                            <span style="color: gray">{{ $t('annual.no-periods') }}</span>
                        </div>


                        <b-form-group style="margin-top: 10px">
                            <b-form-radio v-for="aaRequest in allRequests"
                                          v-model="selectedNew"
                                          :value="aaRequest.id">
                                <span>{{ formatToDate(aaRequest.PeriodStartDate) }}</span>&nbsp-&nbsp
                                <span>{{ formatToDate(aaRequest.PeriodEndDate) }}</span>

                                <div v-if="aaRequest.RegistrationEndDate" class="regdeadline">
                                    <span>{{ $t('annual.reg-deadline') }}: </span>
                                    <span>{{ formatToDate(aaRequest.RegistrationEndDate) }}</span>
                                </div>

                            </b-form-radio>

                        </b-form-group>
                    </div>
                </b-overlay>
            </b-modal>

        </div>


    </div>
</template>

<script>
export default {
    name: "AnnualAccreditation",
    data() {
        return {
            accrProcedure: null,
            annualAccEnabled: false,
            myRequests: [],
            allRequests: [],
            selectedNew: null,
            list: [],
            showModalLoading: false,
            noPeriods: false
        }
    },
    created() {
        this.accrProcedure = get_MIX_NATO_ACCREDITATION_PROCEDURE()
        this.checkIfUserIsResidentInBelgium();
    },
    methods: {
        checkIfUserIsResidentInBelgium() {
            this.showLoadingApp(true);
            axios.get('/annual-accreditation/enabled').then(response => {
                this.annualAccEnabled = response.data.data.enabled;
                this.getAllForPressContact();
            });
        },
        onNewRequest() {
            this.$bvModal.show('modal-new-request');
            this.getAllAvailablePeriods();
        },
        onEditRequest(accId) {
            this.$router.push('/home/annual-accreditation/request/' + accId);
        },
        getAllAvailablePeriods() {
            this.showModalLoading = true;
            this.selectedNew = null;
            this.allRequests = [];
            this.noPeriods = false;

            axios.get('/annual-accreditation/all-available').then(response => {
                this.allRequests = response.data.data;

                if (!this.allRequests || this.allRequests.length === 0) {
                    this.noPeriods = true;
                } else if (this.allRequests.length === 1) {
                    //select first
                    this.selectedNew = this.allRequests[0].id;
                }

                this.showModalLoading = false;
            });
        },
        goToDetail() {
            if (this.selectedNew) {
                this.$router.push('/home/annual-accreditation/request/new/' + this.selectedNew);
            }
        },
        getAllForPressContact() {
            axios.get('/annual-accreditation/all').then(response => {
                this.list = response.data.data;
                this.showLoadingApp(false);
            });
        }
    }
}
</script>

<style scoped>

.annual-acc-section {
    padding-bottom: 150px;
}

.help-info-section {
    margin-bottom: 10px;
    font-size: 12px;
}

.onlyForBelgium {
    color: gray;
}

.events {
    padding-bottom: 150px;
}

.event-info {
    background-color: #202020;
    color: white;
    padding: 10px;
}

.event-info-active {
    color: white;
    margin-bottom: 20px;
}

.check-event-section {
    position: absolute;
    top: calc(50% - 18px);
    right: 20px;
}

.eventdate {
    font-size: 16px;
}

.regdeadline {
    font-size: 12px;
}

</style>
