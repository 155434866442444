<template>
    <div>
        <div>
            <h1>{{ $t('home.heading') }}</h1>
            <span>{{ $t('home.home-msg') }}: </span>
            <a :href="accrProcedure" target="_blank">
                {{
                    $t('menu.media-accreditation-procedures')
                }}</a>
            <hr>
        </div>

        <div class="goto-section">
            <div class="row">
                <div class="col-md-3">
                    <div class="pane">
                        <router-link :to="{ path: '/login' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-sign-in-alt"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.login') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="pane">
                        <router-link :to="{ path: '/register' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-user-plus"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.register') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="pane">
                        <router-link :to="{ path: '/forgot-password' }">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-lock"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('menu.forgot-password') }}</span>
                                </div>
                            </div>
                        </router-link>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="pane">
                        <a :href="accrProcedure" target="_blank">
                            <div>
                                <div class="icon-section">
                                    <i class="fas fa-question"></i>
                                </div>
                                <div class="text-section">
                                    <span>{{ $t('home.help') }}</span>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: "IndexLoggedOut",
    data() {
        return {
            accrProcedure: null
        }
    },
    created() {
        this.accrProcedure = get_MIX_NATO_ACCREDITATION_PROCEDURE()
    },
}
</script>

<style>

.pane {
    /*border: 2px solid #eaeaea;*/
    cursor: pointer;
    padding: 20px;
    background-color: black;
    margin-top: 10px;
}

.pane:hover {
    background-color: var(--nato-blue);
}


.pane a:hover {
    text-decoration: none;
    color: white;
}

.icon-section {
    text-align: center;
    font-size: 60px;
    color: white;
}

.text-section {
    text-align: center;
    color: white;
    font-size: 25px;
}

.goto-section {
    margin-top: 30px;
}

@media (max-width: 1088px) and (min-width: 767px) {
    .pane {
        min-height: 205px;
    }
}

</style>
