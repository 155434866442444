<template>
    <div class="annual-acc-section">
        <h1>{{ $t('annual.heading') }}</h1>
        <span>{{ $t('annual.annual-info') }}</span>
        <hr>

        <div class="help-info-section">
            <i class="fas fa-info-circle"></i>
            <span>{{ $t('annual.help') }}</span>
            <a :href="accrProcedure" target="_blank">
                <span>{{ $t('menu.media-accreditation-procedures') }}</span>
            </a>
        </div>

        <div v-if="!annualAccEnabled">
            <span class="onlyForBelgium">{{ $t('annual.onlyForBelgium') }}</span>
        </div>

        <div v-if="annualAccEnabled">
            <div class="help-info-section">
                <i class="fas fa-info-circle"></i>
                <span>{{ $t('annual.changes-data') }}</span>
                <router-link :to="{ path: '/home/personal-data' }">
                    <span>{{ $t('menu.personal-data') }}</span>
                </router-link>
            </div>

            <div class="form-section">
                <b-form ref="form" autocomplete="off" @submit.prevent="onSubmit">
                    <div class="annual-form">

                        <br>
                        <h5>{{ $t('annual.accreditation-period') }}</h5>
                        <hr>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <label>{{ $t('annual.state') }}</label>
                                    </div>
                                    <div class="col-md-9">
                                        <span :style="'color:' + getAnnualStateColor(details.State) ">{{
                                                getAnnualStateText(details.State)
                                            }}</span>
                                    </div>
                                </div>
                            </div>
                            <!--                            <div class="col-md-6" v-if="details.State === '99'">-->
                            <!--                                <div class="row mb-3">-->
                            <!--                                    <div class="col-md-3">-->
                            <!--                                        <label>{{ $t('annual.reject-reason') }}</label>-->
                            <!--                                    </div>-->
                            <!--                                    <div class="col-md-9">-->
                            <!--                                        <b-form-textarea-->
                            <!--                                            type="text"-->
                            <!--                                            :disabled="true"-->
                            <!--                                            v-model="details['RejectComment']"-->
                            <!--                                            size="sm">-->
                            <!--                                        </b-form-textarea>-->
                            <!--                                    </div>-->
                            <!--                                </div>-->
                            <!--                            </div>-->

                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <label>{{ $t('annual.acc-period-from') }}</label>
                                    </div>
                                    <div class="col-md-9">
                                        <date-time-picker
                                            :disabled="true"
                                            :field="'PeriodStartDate'"
                                            :formdata="periodConfig">
                                        </date-time-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <label>{{ $t('annual.acc-period-to') }}</label>
                                    </div>
                                    <div class="col-md-9">
                                        <date-time-picker
                                            :disabled="true"
                                            :field="'PeriodEndDate'"
                                            :formdata="periodConfig">
                                        </date-time-picker>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <label>{{ $t('annual.reg-period-from') }}</label>
                                    </div>
                                    <div class="col-md-9">
                                        <date-time-picker
                                            :disabled="true"
                                            :field="'RegistrationStartDate'"
                                            :formdata="periodConfig">
                                        </date-time-picker>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <label>{{ $t('annual.reg-period-to') }}</label>
                                    </div>
                                    <div class="col-md-9">
                                        <date-time-picker
                                            :disabled="true"
                                            :field="'RegistrationEndDate'"
                                            :formdata="periodConfig">
                                        </date-time-picker>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>
                        <h5>{{ $t('annual.personal-info') }}</h5>
                        <hr>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-3">
                                    <div class="col-md-3">
                                        <label>{{ $t('annual.employee-status') }}</label>
                                        <span><b>*</b></span>
                                    </div>
                                    <div class="col-md-9">
                                        <b-form-select v-model="details.RepresentativeType" :disabled="!isEditable()"
                                                       :options="employeeStatus"
                                                       required
                                                       size="sm"
                                                       text-field="SysValue_EN"
                                                       value-field="SysInfoID">
                                        </b-form-select>
                                    </div>
                                </div>

                                <div :hidden="!(periodConfig['Document1_YN']==='Y')" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['Label2_EN'] : periodConfig['Label2_FR']
                                            }}
                                            <span v-if="periodConfig['Document1_Required'] === 'Y'"><b>*</b></span>
                                        </label>
                                        <div>
                                            <span class="file-info-label">[Max. {{
                                                    maxFileSize
                                                }}MB / ' jpg, pdf ']</span>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <file-handler
                                            :disabled="!isEditable()"
                                            :formdata="details"
                                            :required="periodConfig['Document1_Required']==='Y' && periodConfig['Document1_YN']==='Y'"
                                            :showButtons="true"
                                            :showDelete="false"
                                            :showDownload="true"
                                            :showImage="false"
                                            acceptTypes=".jpg, .jpeg, .pdf"
                                            download_endpoint="/annual-accreditation/download"
                                            eventId="0"
                                            field="doc1"
                                            idField="id"
                                            image_endpoint="/annual-accreditation/document"
                                            placeholder="fa-user-circle"
                                            upload_endpoint="/annual-accreditation/upload"
                                        >
                                        </file-handler>
                                    </div>
                                </div>

                                <div :hidden="!(periodConfig['Document2_YN']==='Y')" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['Label3_EN'] : periodConfig['Label3_FR']
                                            }}
                                            <span v-if="periodConfig['Document2_Required'] === 'Y'"><b>*</b></span>
                                        </label>
                                        <div>
                                            <span class="file-info-label">[Max. {{
                                                    maxFileSize
                                                }}MB / ' jpg, pdf ']</span>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <file-handler
                                            :disabled="!isEditable()"
                                            :formdata="details"
                                            :required="periodConfig['Document2_Required']==='Y' && periodConfig['Document2_YN']==='Y'"
                                            :showButtons="true"
                                            :showDelete="false"
                                            :showDownload="true"
                                            :showImage="false"
                                            acceptTypes=".jpg, .jpeg, .pdf"
                                            download_endpoint="/annual-accreditation/download"
                                            eventId="0"
                                            field="doc2"
                                            idField="id"
                                            image_endpoint="/annual-accreditation/document"
                                            placeholder="fa-user-circle"
                                            upload_endpoint="/annual-accreditation/upload"
                                        >
                                        </file-handler>
                                    </div>
                                </div>

                                <div :hidden="!(periodConfig['Document3_YN']==='Y')" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['Label4_EN'] : periodConfig['Label4_FR']
                                            }}
                                            <span v-if="periodConfig['Document3_Required'] === 'Y'"><b>*</b></span>
                                        </label>
                                        <div>
                                            <span class="file-info-label">[Max. {{
                                                    maxFileSize
                                                }}MB / ' jpg, pdf ']</span>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <file-handler
                                            :disabled="!isEditable()"
                                            :formdata="details"
                                            :required="periodConfig['Document3_Required']==='Y' && periodConfig['Document3_YN']==='Y'"
                                            :showButtons="true"
                                            :showDelete="false"
                                            :showDownload="true"
                                            :showImage="false"
                                            acceptTypes=".jpg, .jpeg, .pdf"
                                            download_endpoint="/annual-accreditation/download"
                                            eventId="0"
                                            field="doc3"
                                            idField="id"
                                            image_endpoint="/annual-accreditation/document"
                                            placeholder="fa-user-circle"
                                            upload_endpoint="/annual-accreditation/upload"
                                        >
                                        </file-handler>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div :hidden="!(periodConfig['Photo_YN']==='Y')" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['Label1_EN'] : periodConfig['Label1_FR']
                                            }}
                                            <span v-if="periodConfig['Photo_Required'] === 'Y'"><b>*</b></span>
                                        </label>
                                        <button v-b-tooltip.hover.righttop :title="$t('registration.photo-info-msg')"
                                                class="tooltip-info tooltip-button"
                                                type="button">
                                            <i class="fa fa-info-circle"></i>
                                        </button>
                                        <div>
                                            <span class="file-info-label">[Max. {{ maxFileSize }}MB / ' jpg ']</span>
                                        </div>
                                    </div>
                                    <div class="col-md-9">
                                        <file-handler
                                            :disabled="!isEditable()"
                                            :formdata="details"
                                            :required="periodConfig['Photo_Required']==='Y' && periodConfig['Photo_YN']==='Y'"
                                            :showButtons="true"
                                            :showDelete="false"
                                            :showDownload="true"
                                            :showImage="true"
                                            acceptTypes=".jpg, .jpeg"
                                            download_endpoint="/annual-accreditation/download"
                                            eventId="0"
                                            field="Photo"
                                            idField="id"
                                            image_endpoint="/annual-accreditation/document"
                                            placeholder="fa-user-circle"
                                            upload_endpoint="/annual-accreditation/upload"
                                        >
                                        </file-handler>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <br>
                        <h5>{{ $t('annual.additional-info') }}</h5>
                        <hr>

                        <div class="row">
                            <div class="col-md-6">
                                <div v-if="periodConfig['FreeText1_YN']==='Y'" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['FreeText1_EN'] : periodConfig['FreeText1_FR']
                                            }}
                                            <span><b>*</b></span>
                                        </label>
                                    </div>
                                    <div class="col-md-9">
                                        <b-form-input
                                            v-model="details['AccreditationFreeText1']"
                                            :disabled="!isEditable()"
                                            :required="true"
                                            size="sm"
                                            type="text">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div v-if="periodConfig['FreeText2_YN']==='Y'" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['FreeText2_EN'] : periodConfig['FreeText2_FR']
                                            }}
                                            <span><b>*</b></span>
                                        </label>
                                    </div>
                                    <div class="col-md-9">
                                        <b-form-input
                                            v-model="details['AccreditationFreeText2']"
                                            :disabled="!isEditable()"
                                            :required="true"
                                            size="sm"
                                            type="text">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div v-if="periodConfig['FreeText3_YN']==='Y'" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['FreeText3_EN'] : periodConfig['FreeText3_FR']
                                            }}
                                            <span><b>*</b></span>
                                        </label>
                                    </div>
                                    <div class="col-md-9">
                                        <b-form-input
                                            v-model="details['AccreditationFreeText3']"
                                            :disabled="!isEditable()"
                                            :required="true"
                                            size="sm"
                                            type="text">
                                        </b-form-input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div v-if="atLeastOneOption()" class="row mb-3">
                                    <div class="col-md-3">
                                        <label>
                                            {{
                                                $t('attendance.select-one')
                                            }}
                                            <span><b>*</b></span>
                                        </label>
                                    </div>
                                    <div class="col-md-9">
                                        <b-form-checkbox v-for="option in [1,2,3,4,5]"
                                                         v-if="periodConfig['Option'+ option + '_YN'] == 'Y'"
                                                         :key="option"
                                                         v-model="details['AccreditationOption'+option+'_YN']"
                                                         :disabled="!isEditable()"
                                                         :name="periodConfig['Option'+ option + '_YN']"
                                                         :required="!(details['AccreditationOption1_YN'] == 'Y' ||
                                             details['AccreditationOption2_YN'] == 'Y' ||
                                             details['AccreditationOption3_YN'] == 'Y' ||
                                             details['AccreditationOption4_YN'] == 'Y' ||
                                             details['AccreditationOption5_YN'] == 'Y')"
                                                         class="option-checkbox"
                                                         switch
                                                         unchecked-value="N"
                                                         value="Y">
                                            {{
                                                $i18n.locale == 'en' ? periodConfig['Option' + option + '_EN'] : periodConfig['Option' + option + '_FR']
                                            }}
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <br>
                        <h5>{{ $t('annual.support-info') }}</h5>
                        <hr>

                        <div v-if="isEditable()">
                            <span style="cursor: pointer" @click="onNewOptionalDocument">
                                <i class="fas fa-plus-circle"></i>
                                <span>{{ $t('annual.add-document') }}</span>
                             </span>
                        </div>

                        <br>

                        <div :key="componentKey">
                            <div v-for="doc in details.AddDocuments" v-if="!doc.toDelete" class="row">

                                <div class="col-md-6">

                                    <div class="row mb-3">
                                        <div class="col-md-2" style="display: flex; align-items: center">
                                            <b-button v-if="isEditable()"
                                                      class="nato-default-btn"
                                                      size="sm"
                                                      @click="onDeleteOptionalDocument(doc)">
                                                <i class="fas fa-trash"></i>
                                            </b-button>
                                        </div>

                                        <div class="col-md-3">
                                            <label>
                                                {{ $t('annual.optional-document') }}
                                                <span><b>*</b></span>
                                            </label>
                                            <div>
                                                <span class="file-info-label">[Max. {{
                                                        maxFileSize
                                                    }}MB / ' jpg, pdf ']</span>
                                            </div>
                                        </div>
                                        <div class="col-md-7">
                                            <file-handler
                                                :disabled="!isEditable()"
                                                :formdata="doc"
                                                :required="true"
                                                :showButtons="true"
                                                :showDelete="false"
                                                :showDownload="true"
                                                :showImage="false"
                                                acceptTypes=".jpg, .jpeg, .pdf"
                                                download_endpoint="/annual-accreditation/download"
                                                eventId="0"
                                                field="Value"
                                                idField="id"
                                                image_endpoint="/annual-accreditation/document"
                                                placeholder="fa-user-circle"
                                                upload_endpoint="/annual-accreditation/upload">
                                            </file-handler>
                                        </div>
                                    </div>

                                </div>

                                <div class="col-md-6">

                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <label>
                                                {{ $t('annual.purpose-content') }}
                                                <span><b>*</b></span>
                                            </label>
                                        </div>
                                        <div class="col-md-9">

                                            <b-form-textarea
                                                v-model="doc['Description']"
                                                :disabled="!isEditable()"
                                                :required="true"
                                                size="sm"
                                                type="text">
                                            </b-form-textarea>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <hr>

                        <div v-for="option in [1,2]" v-if="periodConfig['Disclaimer'+ option + '_YN'] === 'Y'"
                             class="row">
                            <div class="col-12">
                                <b-form-checkbox
                                    v-model="details['AccreditationDisclaimer'+option+'_YN']"
                                    :disabled="!isEditable()"
                                    :name="periodConfig['Disclaimer' + option + '_EN']"
                                    required
                                    switch
                                    unchecked-value="N"
                                    value="Y">
                                    {{
                                        $i18n.locale == 'en' ? periodConfig['Disclaimer' + option + '_EN'] : periodConfig['Disclaimer' + option + '_FR']
                                    }}
                                    <span><b>*</b></span>
                                </b-form-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <b-form-checkbox :checked="!isEditable()" :disabled="!isEditable()" name="accept1"
                                                 required switch>
                                    {{ $t('annual.agree1') }}&nbsp;<a :href="accrProcedure" target="_blank">
                                    <span>{{ $t('menu.media-accreditation-procedures') }}</span>
                                </a>
                                    <span><b>*</b></span>
                                </b-form-checkbox>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <b-form-checkbox :checked="!isEditable()" :disabled="!isEditable()" name="accept4"
                                                 required switch>
                                    {{ $t('annual.agree4') }}
                                    <span><b>*</b></span>
                                </b-form-checkbox>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12">
                                <b-form-checkbox :checked="!isEditable()" :disabled="!isEditable()" name="accept5"
                                                 required switch>
                                    {{ $t('annual.agree5') }}
                                    <span><b>*</b></span>
                                </b-form-checkbox>
                            </div>
                        </div>

                        <hr>

                        <div v-if="isEditable()" style="text-align: right">
                            <b-button v-b-tooltip.hover
                                      :disabled="!isEditable()" :title="$t('annual.save-draft-msg')"
                                      class="nato-default-btn nato-default-btn-blue"
                                      size="lg"
                                      style="min-width: 180px" type="button"
                                      @click="saveDraft">
                                {{ $t('annual.save-draft') }}
                            </b-button>

                            <b-button v-if="!isNewview()" v-b-tooltip.hover
                                      :disabled="!isEditable()" class="nato-default-btn nato-default-btn-red"
                                      size="lg"
                                      style="min-width: 180px"
                                      type="button" @click="deleteDraft">
                                {{ $t('annual.delete-draft') }}
                            </b-button>

                            <b-button ref="submit"
                                      v-b-tooltip.hover :disabled="!isEditable()" :title="$t('annual.submit-draft-msg')"
                                      class="nato-default-btn"
                                      size="lg" style="min-width: 180px" type="submit"
                                      value="submit">
                                {{ $t('annual.submit-request') }}
                            </b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>


    </div>
</template>

<script>
export default {
    name: "AnnualAccreditation",
    data() {
        return {
            accrProcedure: null,
            annualAccEnabled: false,
            periodConfig: {},
            employeeStatus: null,
            details: {},
            maxFileSize: 9,
            componentKey: 0
        }
    },
    created() {
        this.accrProcedure = get_MIX_NATO_ACCREDITATION_PROCEDURE()
        this.maxFileSize = get_MIX_ATTENDANCE_MAX_FILESIZE();
        this.checkIfUserIsResidentInBelgium();
    },
    methods: {
        onSubmit(event) {
            this.showLoadingApp(true);

            axios.post('/annual-accreditation/submit?' +
                'request_id=' + this.$route.params.id +
                '&aa_id=' + this.$route.params.aaid, this.details).then(response => {

                // if(this.isNewview()) {
                //     this.$router.push('/home/annual-accreditation/request/'+response.data.data);
                // }
                // this.getRequestDetails();

                this.backToList();
            }).catch(error => {
                this.showLoadingApp(false);
                this.showErrorToast(this.$t('basics.fail-saved'), 5000);
            });

        },
        checkIfUserIsResidentInBelgium() {
            this.showLoadingApp(true);
            axios.get('/annual-accreditation/enabled').then(response => {
                this.annualAccEnabled = response.data.data.enabled;
                this.getRequestDetails();
            });
        },
        backToList() {
            this.$router.push('/home/annual-accreditation/');
        },
        getRequestDetails() {
            axios.get('/annual-accreditation/details?' +
                'request_id=' + this.$route.params.id +
                '&aa_id=' + this.$route.params.aaid)
                .then(response => {
                    this.showLoadingApp(false);
                    this.periodConfig = response.data.data.config;
                    this.employeeStatus = response.data.data.employeeStatus;
                    if (response.data.data.requestDetails) {
                        this.details = response.data.data.requestDetails;
                    }

                });
        },
        saveDraft() {
            this.showLoadingApp(true);

            axios.post('/annual-accreditation/save?' +
                'request_id=' + this.$route.params.id +
                '&aa_id=' + this.$route.params.aaid, this.details).then(response => {
                // if(this.isNewview()) {
                //     this.$router.push('/home/annual-accreditation/request/'+response.data.data);
                // }
                // this.getRequestDetails();

                this.backToList();
            }).catch(error => {
                this.showLoadingApp(false);
                this.showErrorToast(this.$t('basics.fail-saved'), 5000);
            });
        },
        deleteDraft() {
            this.showDeleteConfirm(this.$t('basics.really-delete')).then(result => {
                if (result) {
                    this.showLoadingApp(true);

                    axios.delete('/annual-accreditation/delete?request_id=' + this.$route.params.id).then(response => {
                        this.showLoadingApp(false);
                        this.$router.push('/home/annual-accreditation');
                    }).catch(error => {
                        this.showLoadingApp(false);
                        this.$router.push('/home/annual-accreditation');
                    });
                }
            })
        },
        isNewview() {
            if (this.$route.params.aaid) {
                return true;
            }
            return false;
        },
        onNewOptionalDocument() {
            if (!this.details.AddDocuments) {
                this.details.AddDocuments = [];
            }

            this.details.AddDocuments.push({
                id: null,
                Value: null,
                Key: null,
                Description: null
            })
            this.componentKey++;
        },
        onDeleteOptionalDocument(doc) {
            this.showDeleteConfirm(this.$t('basics.really-delete')).then(result => {
                if (result) {
                    if (doc) {
                        doc.toDelete = true;
                        this.componentKey++;
                    }
                }
            })
        },
        isEditable() {
            if (this.details.State && this.details.State !== '0') {
                return false;
            }

            if (this.periodConfig && !this.periodConfig.Editable) {
                return false;
            }
            return true;
        },
        atLeastOneOption() {
            let result = false;
            let arr = ['1', '2', '3', '4', '5'];
            arr.forEach(option => {
                if (this.periodConfig['Option' + option + '_YN'] == 'Y') {
                    result = true;
                }
            })
            return result;
        }
    }
}
</script>

<style scoped>

.annual-acc-section {
    padding-bottom: 150px;
}

.help-info-section {
    margin-bottom: 10px;
    font-size: 12px;
}

.office {
    border: 2px solid #eaeaea;
    padding: 20px;
    max-width: 315px;
}

.office-heading {
    font-weight: bold;
    font-size: 21px;
}

.annual-form {
    margin-top: 10px;
}

.custom-switch {
    font-size: 15px;
}

.onlyForBelgium {
    color: gray;
}

.option-checkbox {
    margin-bottom: 5px;
}

/deep/ input[type="checkbox"]:disabled {
    opacity: 0 !important;
}

.file-info-label {
    font-size: 11px;
}

</style>
