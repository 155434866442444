<template>
    <div>
        <date-picker
            :config="dateTimePickerOptions"
            :disabled="disabled"
            :required="required"
            :value="formattedDate"
            class="form-control form-control-sm"
            @dp-change="onDateInput">
        </date-picker>
    </div>
</template>

<script>
export default {
    name: "DateTimePicker",
    props: {
        formdata: null,
        field: null,
        showTime: false,
        disabled: false,
        required: false,
    },
    data() {
        return {
            dateTimePickerOptions: {
                locale: this.$i18n.locale,
                sideBySide: true,
                extraFormats: ['YYYY-MM-DD HH:mm:ss'],
                format: this.getDatePickerFormat(),
                useCurrent: false
            },
            formattedDate: null,
            initialTime: null
        }
    },
    methods: {
        onDateInput(val) {
            var parsedDate = null;
            if (val != null && val.date) {

                if (this.showTime) {
                    parsedDate = val.date.format('YYYY-MM-DD HH:mm:00');
                } else {

                    if (this.initialTime != null) {
                        parsedDate = val.date.format('YYYY-MM-DD ' + this.initialTime);
                    } else {
                        parsedDate = val.date.format('YYYY-MM-DD 00:00:00');
                    }

                }
            } else {
                parsedDate = null;
            }

            if (parsedDate == 'Invalid date') {
                parsedDate = null;
            }

            this.formattedDate = parsedDate;
            this.formdata[this.field] = parsedDate;
        },
        getDatePickerFormat(para) {
            if (this.$i18n.locale == 'en') {
                if (this.showTime) {
                    return 'MMMM DD, YYYY HH:mm';
                } else {
                    return 'MMMM DD, YYYY';
                }
            } else {
                if (this.showTime) {
                    return 'DD MMMM YYYY HH:mm';
                } else {
                    return 'DD MMMM YYYY';
                }
            }
        },
        updateDate() {
            if (!this.showTime && this.formdata[this.field] != null) {
                //some old dates are stored with a time e.g. 01:00am.but here we dont need a time, only date. so we would always get unsaved changes
                var time = this.$moment(new Date(this.formdata[this.field])).format('HH:mm:ss');
                if (time != '00:00:00') {
                    this.initialTime = time;
                }
            }
            this.formattedDate = this.formdata[this.field];
        }
    },
    watch: {
        getFormdataField() {
            this.updateDate();
        }
    },
    computed: {
        getFormdataField() {
            return this.formdata[this.field];
        }
    }
}
</script>

<style scoped>

</style>
