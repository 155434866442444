<template>
    <div>

        <h1>{{ $t('passwordChange.passwordReset') }}</h1>
        <span>{{ $t('passwordReset.forgotPassword') }}
              <button v-b-tooltip.hover.righttop :title="$t('passwordReset.password-tooltip')" class="tooltip-info toolbar-button"
                      type="button">
                <i class="fa fa-info-circle"></i>
            </button>
        </span>
        <hr>

        <b-form class="" @submit.prevent="onReset">
            <div class="form-section">
                <div class="icon-section">
                    <i class="fas fa-sync"></i>
                </div>
                <b-input v-model="password_new"
                         :placeholder=" $t('passwordChange.newPassword') "
                         required
                         size="sm"
                         type="password">

                </b-input>
                <b-input v-model="password_new_confirm"
                         :placeholder=" $t('passwordChange.confirmPassword') "
                         required
                         size="sm"
                         type="password">

                </b-input>

                <b-button :disabled="!password_new || (password_new_confirm != password_new)" class="nato-default-btn" type="submit"
                          value="submit">
                    {{ $t('passwordChange.changePassword') }}
                </b-button>
            </div>
        </b-form>

    </div>
</template>

<script>
export default {
    name: "PasswordReset",
    data() {
        return {
            email: "",
            password_new: null,
            password_new_confirm: null,
            token: null,
            showLoading: false,
        }
    },
    beforeMount() {
        this.showLoadingApp(true);
        if (this.$route.params.token) {
            // check reset token
            axios.get('/find/' + this.$route.params.token)
                .then(response => {
                    this.token = response.data.data.token;
                    this.email = response.data.data.email;
                    this.showLoadingApp(false);
                }).catch(() => {
                this.showLoadingApp(false);
                this.showErrorToast(this.$t('passwordReset.token-expired'));
                setTimeout(() => this.$router.push('/password-reset'), 2000);
            });
        } else {
            this.showLoadingApp(false);
            this.$router.push('/login');
        }
    },
    methods: {
        onReset() {
            this.showLoadingApp(true);
            axios.post("/reset", {
                'email': this.email,
                'token': this.token,
                'password': this.password_new,
                'password_confirmation': this.password_new_confirm,
            }).then(() => {
                this.showLoadingApp(false);
                this.showSuccessToast(this.$t('passwordChange.passwordChanged'));
                setTimeout(() => this.$router.push('/login'), 2000);
            }).catch((error) => {
                let errors = error.response.data.error.errors;
                if (errors) {
                    if (errors.password) {
                        this.showErrorToast(this.$t('passwordChange.passwordRequirementsError'));
                        this.showLoadingApp(false);
                        return;
                    }
                }
                this.showLoadingApp(false);
                this.showErrorToast(this.$t('passwordChange.generalPasswordChangeError'));
            });
        }
    }
}
</script>
<style scoped>

.form-section {
    margin: 30px auto;
    max-width: 500px;
    border: 2px solid #eaeaea;
    padding: 20px;
}

.form-section input {
    margin-bottom: 10px;
}

.icon-section {
    text-align: center;
    font-size: 60px;
    color: #CBCBCB;
}

.toolbar-button {
    background-color: transparent !important;
    color: var(--grid-toolbar-gray);
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: none;
    margin: 0 5px;
    font-size: 18px;
}


.toolbar-button:focus {
    outline: 0 !important;
}

.tooltip-info {
    cursor: default !important;
}

</style>
