<template>
    <div>
        Page not found!
    </div>
</template>

<script>
export default {
    name: "PageNotFound",
    methods: {}
}
</script>

<style scoped>


</style>
