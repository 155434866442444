<template>
    <div class="app-welcome">
        <div class="content-site app-welcome-inner">
            <div>
                <span class="welcome">{{ $t('welcome.welcome-to') }}</span>
            </div>

            <div>
                <span class="app-name">{{ $t('welcome.app-name') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Welcome"
}
</script>

<style scoped>

.app-welcome {
    padding: 20px 0 20px 0;
    background-color: black;
    color: white;
}

.app-welcome-inner {
    text-align: center;
}

.welcome {
    font-size: 16px;
}

.app-name {
    font-size: 35px;
}

@media (max-width: 680px) {
    .welcome {
        font-size: 13px;
    }

    .app-name {
        font-size: 17px;
    }
}

</style>
