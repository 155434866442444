<template>
    <div>

        <h1>{{ $t('passwordChange.passwordReset') }}</h1>
        <span>{{ $t('passwordReset.forgotPassword') }}</span>
        <hr>

        <b-form v-if="!emailWasSend" class="" @submit.prevent="onReset">
            <div class="form-section">
                <div class="icon-section">
                    <i class="fas fa-question"></i>
                </div>
                <b-input v-model="email"
                         :placeholder="$t('passwordReset.emailAdress')"
                         required
                         size="sm"
                         type="text"></b-input>

                <b-button class="nato-default-btn" size="sm" type="submit" value="submit">{{
                        $t('passwordReset.reset')
                    }}
                </b-button>
            </div>
        </b-form>

        <div v-if="emailWasSend">
            <p>{{ $t('passwordReset.emailWasSend') }}</p>
        </div>

    </div>
</template>

<script>
export default {
    name: "ForgotPassword",
    data() {
        return {
            email: null,
            emailWasSend: false
        }
    },
    methods: {
        onReset() {
            this.showLoadingApp(true);
            axios.post("/password-reset", {
                'email': this.email,
                'language': localStorage.getItem('language') || 'en'
            }).then(() => {
                this.emailWasSend = true;
                this.showLoadingApp(false);
                this.showSuccessToast(this.$t('passwordReset.emailSend'));
            }).catch(() => {
                this.showLoadingApp(false);
                this.showErrorToast(this.$t('passwordReset.emailNotFound'));
            });
        }
    }
}
</script>

<style scoped>

.form-section {
    margin: 30px auto;
    max-width: 500px;
    border: 2px solid #eaeaea;
    padding: 20px;
}

.form-section input {
    margin-bottom: 10px;
}

.icon-section {
    text-align: center;
    font-size: 60px;
    color: #CBCBCB;
}

</style>
